
import { Options, Vue, Watch } from "vue-property-decorator";
import api from "@/api/clap.ts";
import PersonliableDialog from "@/components/personliabledialog/index.vue";
import fileApi from "@/api/file/index";
@Options({
  name: "Clapdetail",
  components: {
    PersonliableDialog,
  },
})
export default class extends Vue {
  infodata: any = {
    flag: 0,
  };
  queryPloyLoad: any = {};
  show = false;
  showClose = false;
  previewimg = "";
  personFlag = 1;
  subform = {
    changePersonName: "",
    changePersonId: "",
    checkUserName: "",
    checkUserId: "",
  };
  reason = "";
  showModifyForm = false;
  active = -1;
  remark = "";
  uploader = [];
  checkUploader = [];
  mounted() {
    this.queryPloyLoad = this.$route.params;
    console.log("this.queryPloyLoad", this.queryPloyLoad);
    this.fGetDetailData();
  }
  async fGetDetailData() {
    const result: any = await api.mobilesourceCheckgetById(
      this.queryPloyLoad.id
    );
    if (result.code !== 0) {
      return this.$toast.fail(result.msg);
    }
    this.infodata = result.data;
    if (
      this.infodata.dealList.length > 0 &&
      this.infodata.dealList[0].status === 3
    ) {
      this.active = 0;
    }
  }
  afterRead(files: any) {
    let filearr = null;
    if (!Array.isArray(files)) {
      filearr = [files];
    } else {
      filearr = [...files];
    }
    filearr.map((file) => {
      file.status = "uploading";
      file.message = "上传中...";
      fileApi
        .uploadFile("photographmobile/", file.file.name, file.file, true)
        .then((res) => {
          file.status = "done";
          file.id = res.data.id;
        });
    });
  }
  fSumbit() {
    (this as any).$refs.sellForm
      .validate()
      .then(async () => {
        let res = await this.$dialog
          .confirm({
            title: "提示",
            message: "提交事件，是否继续？",
          })
          .catch((err) => err);
        if (res === "confirm") {
          const result: any = await api.mobileanyPhotoallocateUser({
            id: this.queryPloyLoad.id,
            areaId: this.queryPloyLoad.areaId,
            changePersonId: this.subform.changePersonId,
            checkUserId: this.subform.checkUserId,
          });
          if (result.code !== 0) {
            return this.$toast.fail(result.msg);
          }
          this.$toast.success({
            message: result.msg,
            forbidClick: true,
            onClose: () => {
              (this as any).$mitter.emit("clapRefresh");
              this.$router.go(-1);
            },
          });
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
  async examine(type: number) {
    let data = {};
    let arr = "";
    this.uploader.map((item: any) => {
      arr += "," + item.id;
    });
    let checkArr = "";
    this.checkUploader.map((item: any) => {
      checkArr += "," + item.id;
    });
    if (type === 1) {
      data = {
        questionId: this.infodata.id,
        remark: this.remark,
        fileIds: arr.substr(1, arr.length - 1),
        checkFileIds: checkArr.substr(1, checkArr.length - 1),
        status: 3,
      };
    } else {
      data = {
        questionId: this.infodata.id,
        remark: this.remark,
        fileIds: arr.substr(1, arr.length - 1),
        checkFileIds: checkArr.substr(1, checkArr.length - 1),
        status: 1,
      };
    }
    let confirmRes = await this.$dialog
      .confirm({
        title: "是否确认" + (type === 1 ? "通过" : "不通过") + "？",
      })
      .catch((err) => err);
    if (confirmRes === "confirm") {
      api.mobileanyPhotoDealsaveCheck(data).then((res: any) => {
        if (res.code === 0) {
          this.$toast.success({
            message: res.msg,
            forbidClick: true,
            onClose: () => {
              (this as any).$mitter.emit("clapRefresh");
              this.$router.go(-1);
            },
          });
        }
      });
    }
  }
  fClose() {
    this.showClose = true;
  }
  async fEventConfirm() {
    const res: any = await api.closeIgnore({
      questionId: this.queryPloyLoad.id,
      remark: this.reason,
    });
    if (res.code !== 0) {
      return this.$toast.fail(res.msg);
    }
    this.$toast.success({
      message: "关闭成功",
      forbidClick: true,
      onClose: () => {
        this.reason = "";
        this.showClose = true;
        (this as any).$mitter.emit("clapRefresh");
        this.$router.go(-1);
      },
    });
  }
  fEventCancel() {
    this.reason = "";
    this.showClose = false;
  }
  fOpenchangePerson(num: number) {
    this.personFlag = num;
    (this as any).$refs.personliable.show = true;
  }
  setChangePerson(obj: any) {
    if (this.personFlag === 1) {
      this.subform.changePersonName = obj.personliableName;
      this.subform.changePersonId = obj.changePersonId;
    } else {
      this.subform.checkUserName = obj.personliableName;
      this.subform.checkUserId = obj.changePersonId;
    }
  }
  previewimgfun(arg: string) {
    this.previewimg = arg;
    this.show = true;
  }
  fHandle() {
    (this as any).$refs.handleForm
      .validate()
      .then(() => {
        this.$dialog.confirm({
          title: "是否确认提交？",
          message: "",
          beforeClose: (action: string) => {
            if (action === "confirm") {
              let arr = "";
              this.uploader.map((item: any) => {
                arr += "," + item.id;
              });
              let checkArr = "";
              this.checkUploader.map((item: any) => {
                checkArr += "," + item.id;
              });
              const data = {
                questionId: this.infodata.id,
                fileIds: arr.substr(1, arr.length - 1),
                checkFileIds: checkArr.substr(1, checkArr.length - 1),
                remark: this.remark,
              };
              api.mobileanyPhotoDealsaveDeal(data).then((res: any) => {
                if (res.code !== 0) {
                  return this.$toast.fail(res.msg);
                }
                this.$toast.success({
                  message: res.msg,
                  forbidClick: true,
                  onClose: () => {
                    (this as any).$mitter.emit("clapRefresh");
                    this.$router.go(-1);
                    return true;
                  },
                });
              });
            } else {
              return true;
            }
          },
        });
      })
      .catch((err: any) => err);
  }
}
